import React from 'react'
import HeroSection from './index/heroSection'
import LogosMentions from './index/logosMentions'
import Features from './index/features'
import TargetMarket from './index/targetMarket'
import HowItWorks from './index/howItWorks'
import Contact from './index/contact'
import Footer from './layouts/footer'

function index() {
  return (
    <div>
      <HeroSection />
      <LogosMentions />
      <Features />
      <HowItWorks />
      <TargetMarket />
      <Contact />
      <Footer />
    </div>
  )
}

export default index
