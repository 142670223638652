import React from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// import Landing Pages
import Index from './pages/index'

// import not found page
import NotFoundPage from './pages/notFound'

function App() {
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />

          {/* not found page */}
          <Route path='*' element={< NotFoundPage />} />
        </Routes>

      </BrowserRouter>

    </div>
  );
}

export default App;