import React from 'react'

// thePartnersLogos
import microsoft4StartupsLogo from '../../../assets/images/partners-logos/microsoft4StartupsLogo.png'
import whoLogo from '../../../assets/images/partners-logos/who-logo.png'
import unicefLogo from '../../../assets/images/partners-logos/unicef-logo.png'
import fdaLogo from '../../../assets/images/partners-logos/fda-logo.png'
import zimMinstryofHealth from '../../../assets/images/partners-logos/ZW-ministryofhealth-logo.png'
import ycombinatorLogo from '../../../assets/images/partners-logos/ycombinator-logo.png'
import google4startups from '../../../assets/images/partners-logos/google4Startups-logo.png'

function logosMentions() {
    return (
        <section className="py-12 sm:py-16 lg:py-20 xl:py-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="px-6 text-center sm:px-0">
                    <h2 className="text-2xl font-semibold text-gray-900 sm:text-3xl lg:text-4xl">
                        Organizations we target to work with
                    </h2>
                </div>

                <div className="flex flex-wrap items-center justify-center max-w-5xl gap-8 mx-auto mt-12 sm:mt-16 sm:gap-y-12 md:gap-16">
                    <img className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-44" src={whoLogo} alt="" />
                    <img className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-44" src={unicefLogo} alt="" />
                    <img className="object-contain w-32 max-w-full sm:h-8 md:h-20 sm:w-44" src={fdaLogo} alt="" />
                    <img className="object-contain w-32 max-w-full sm:h-10 md:h-20 sm:w-44" src={zimMinstryofHealth} alt="" />
                    <img className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-40" src={ycombinatorLogo} alt="" />
                    <img className="object-contain w-32 max-w-full sm:h-8 md:h-9 sm:w-40" src={google4startups} alt="" />
                    {/* <img className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-40" src="https://landingfoliocom.imgix.net/store/collection/saasui/images/cloud-logos/4/ridoria.svg" alt="" />
                    <img className="object-contain w-32 max-w-full sm:h-8 md:h-10 sm:w-40" src="https://landingfoliocom.imgix.net/store/collection/saasui/images/cloud-logos/4/incanto.svg" alt="" /> */}
                </div>

                <div className="mt-12 space-x-6 border-t border-gray-200 sm:mt-16 lg:mt-20">
                    <p className='text-center mt-8 text-sm'>
                        Special Thanks To Our Cloud Solutions Sponsors
                    </p>

                    <div class="flex justify-center mt-4">
                        <div class="MicrosoftImages">
                            <img class="microsoft4StartupsLogo" src={microsoft4StartupsLogo} alt="" />
                        </div>
                    </div>

                </div>


            </div>
        </section>
    )
}

export default logosMentions