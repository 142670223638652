import React from 'react'
import HomePage from '../components/landing/index'

function index() {
  return (
    <div>
      <HomePage />
    </div>
  )
}

export default index
