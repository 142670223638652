
import React from 'react';

const Features = () => {
    return (
        <div>
            <section className="py-12 bg-white sm:py-16 lg:py-20">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="text-left sm:mx-auto sm:text-center lg:max-w-2xl">
                        <div className="inline-flex px-4 py-1.5 mb-10 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600 ">
                            <p className="text-xs font-semibold tracking-widest text-white uppercase">cancers360 detects 5 cancer types</p>
                        </div>

                        <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-4xl font-pj">Providing an efficient solution that will save lives and reduce cancer deaths</h2>
                        <p className="mt-4 text-lg text-gray-700 md:max-w-md md:mx-auto sm:mt-6 font-pj">
                            Our solution, Cancers360 diagonises 5 types of cancer, which are breast, skin, lung, colon and prostate cancer. By detecting cancer at any
                            of its stages especially early stage, we can increase patients chances of survival and successful treatment.
                        </p>
                    </div>

                    <div className="relative mt-12 md:mt-16">
                        <div className="absolute -inset-4 lg:-inset-y-6 lg:-inset-x-8">
                            <div className="w-full h-full max-w-4xl mx-auto opacity-20 blur-lg filter" style={{ background: "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)" }}></div>
                        </div>

                        <div className="relative sm:justify-center">
                            <div className="grid grid-cols-1 gap-5 mt-12 sm:grid-cols-3 lg:mt-20 lg:gap-x-12">
                                <div className="mt-5 bg-white rounded-xl sm:mt-0 border border border-pink-200">
                                    <div className="px-6 py-8">
                                        <div className="flex items-start">
                                            <svg className="flex-shrink-0 w-6 h-6 mt-0.5 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                            <div className="ml-5">
                                                <h3 className="text-lg font-bold text-gray-900 font-pj">Life Saving</h3>
                                                <p className="mt-3 text-base text-gray-900 font-pj">Detects multiple cancers at once early, improving treatment success and saving lives.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 bg-white rounded-xl sm:mt-0 border border border-pink-200">
                                    <div className="px-6 py-8">
                                        <div className="flex items-start">
                                            <svg className="flex-shrink-0 w-6 h-6 mt-0.5 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                            <div className="ml-5">
                                                <h3 className="text-lg font-bold text-gray-900 font-pj">Affordable Low Costs</h3>
                                                <p className="mt-3 text-base text-gray-900 font-pj">Reduces expenses compared to traditional methods, making it accessible to more people.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 bg-white rounded-xl sm:mt-0 border border border-pink-200">
                                    <div className="px-6 py-8">
                                        <div className="flex items-start">
                                            <svg className="flex-shrink-0 w-6 h-6 mt-0.5 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                            <div className="ml-5">
                                                <h3 className="text-lg font-bold text-gray-900 font-pj">Very Flexible</h3>
                                                <p className="mt-3 text-base text-gray-900 font-pj">Adapts to various healthcare settings, from urban clinics to the most remote areas.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 bg-white rounded-xl sm:mt-0 border border border-pink-200">
                                    <div className="px-6 py-8">
                                        <div className="flex items-start">
                                            <svg className="flex-shrink-0 w-6 h-6 mt-0.5 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                            <div className="ml-5">
                                                <h3 className="text-lg font-bold text-gray-900 font-pj">Multi Screening Options</h3>
                                                <p className="mt-3 text-base text-gray-900 font-pj">Diagnoses various cancers, enhancing screening options.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 bg-white rounded-xl sm:mt-0 border border border-pink-200">
                                    <div className="px-6 py-8">
                                        <div className="flex items-start">
                                            <svg className="flex-shrink-0 w-6 h-6 mt-0.5 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                            <div className="ml-5">
                                                <h3 className="text-lg font-bold text-gray-900 font-pj">Revenue Generation</h3>
                                                <p className="mt-3 text-base text-gray-900 font-pj">Offers a sustainable business model through diagnostic services.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 bg-white rounded-xl sm:mt-0 border border border-pink-200">
                                    <div className="px-6 py-8">
                                        <div className="flex items-start">
                                            <svg className="flex-shrink-0 w-6 h-6 mt-0.5 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                            <div className="ml-5">
                                                <h3 className="text-lg font-bold text-gray-900 font-pj">Global Impact</h3>
                                                <p className="mt-3 text-base text-gray-900 font-pj">Addresses a global health issue, benefiting underserved regions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Features;