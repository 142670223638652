import React, { useState } from 'react';

const ComponentName = () => {
    return (
        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20 xl:py-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-32">
                    <div className="flex flex-col self-stretch justify-between">
                        <div className="flex-1">
                            <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                                We would love to hear from you, Get in touch 👋
                            </h2>
                            <p className="mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:mt-6 lg:leading-8">
                                We value your feedback and recommendations. Fell free to contact us through 
                                filling the conatct form and we will get back to you as soon as possible. You can also email us on <span className='font-bold'> talktous@cancers360.org</span>
                            </p>
                        </div>

                        <div className="relative mt-10 overflow-hidden bg-blue-600 lg:mt-0 rounded-3xl -rotate-3">
                            <div
                                className="absolute bottom-0 right-0 -mb-72 -mr-24 border-[6px] opacity-20 border-white rounded-full w-96 h-96">
                            </div>

                            <div className="relative p-6 sm:px-8 sm:py-9 border-2 border border-pink-400">
                                <div className="flex items-center">
                                    <svg className="w-6 h-6 text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                    <svg className="w-6 h-6 text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                    <svg className="w-6 h-6 text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                    <svg className="w-6 h-6 text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                    <svg className="w-6 h-6 text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                </div>
                                <blockquote className="mt-8">
                                    <p className="text-sm font-normal text-white">
                                        "We are deeply committed to Cancers360, and our passion for this solution is unwavering. Our team's 
                                        dedication shines through as we wholeheartedly believe in its potential to make a 
                                        significant impact on the healthcare community."
                                    </p>
                                </blockquote>
                                <div className="flex items-center mt-6">
                                    <img className="object-cover rounded-full w-11 h-11 shrink-0" src="https://landingfoliocom.imgix.net/store/collection/saasui/images/sign-in/3/avatar-male.png"
                                        alt="" />
                                    <div className="ml-4">
                                        <p className="text-base font-semibold text-white">
                                            Collins Jimu
                                        </p>
                                        <p className="text-sm font-normal text-gray-300">
                                            Co-Founder and Senior Software Engineer, Cancers360
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white shadow-xl rounded-2xl border border-pink-200">
                        <div className="p-6 sm:p-10">
                            <h3 className="text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                                Send us a message
                            </h3>
                            <form action="#" method="POST" className="mt-8 space-y-6">
                                <div>
                                    <label for="fullName" className="sr-only">
                                        Your name
                                    </label>
                                    <div>
                                        <input type="text" name="fullName" id="fullName" placeholder="Your name"
                                            className="block w-full px-px py-4 text-base text-gray-900 placeholder-gray-600 bg-white border-b-2 focus:outline-none focus:border-blue-600 focus:ring-0" />
                                    </div>
                                </div>

                                <div>
                                    <label for="email" className="sr-only">
                                        Email address
                                    </label>
                                    <div>
                                        <input type="email" name="email" id="email" placeholder="Email address"
                                            className="block w-full px-px py-4 text-base text-gray-900 placeholder-gray-600 bg-white border-b-2 focus:outline-none focus:border-blue-600 focus:ring-0" />
                                    </div>
                                </div>

                                <div>
                                    <label for="phone" className="sr-only">
                                        Phone
                                    </label>
                                    <div>
                                        <input type="tel" name="phone" id="phone" placeholder="+1-222-333-4444"
                                            className="block w-full px-px py-4 text-base text-gray-900 placeholder-gray-600 bg-white border-b-2 focus:outline-none focus:border-blue-600 focus:ring-0" />
                                    </div>
                                </div>

                                <div>
                                    <label for="message" className="sr-only">
                                        Write your message
                                    </label>
                                    <div>
                                        <textarea name="email" id="email" placeholder="Write your message" rows="4"
                                            className="block w-full px-px py-4 text-base text-gray-900 placeholder-gray-600 bg-white border-b-2 focus:outline-none focus:border-blue-600 focus:ring-0"></textarea>
                                    </div>
                                </div>

                                <button type="submit"
                                    className="inline-flex items-center justify-center w-full px-12 py-4 text-base font-bold leading-7 
                                    text-white transition-all duration-200 bg-gray-900 border-2 border-pink-300 rounded-xl hover:bg-gray-600 
                                    font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                                    Send message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ComponentName;