import React from 'react'
import landingDashboardScreen from "../../../assets/images/landingDashboardScreen.png"
import Header from '../layouts/navBar'

function heroSection() {
    return (
        <div>
            <div className="overflow-x-hidden bg-gray-50">
                
                <Header />

                <section className="pt-12 bg-gray-50">
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-4">
                        <div className="max-w-2xl mx-auto text-center">

                            <p className="mt-5 font-bold leading-tight text-gray-900 sm:leading-tight sm:text-4lg lg:text-6xl lg:leading-tight font-pj">
                                Upto Date Globally, Cancer is a leading cause of death.
                                <span className="relative inline-flex sm:inline">
                                    <span className="bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] blur-lg filter opacity-30 w-full h-full absolute inset-0"></span>
                                </span>
                            </p>

                            <h1 className="mt-8 px-2 text-2sm text-gray-600 font-inter">
                                According to the World Health Organization (WHO), every 2 in 10 deaths are caused by cancer. Early diagnosis is very critical to decrease mortality rates.
                            </h1>

                            <h1 className="mt-4 px-2 text-2sm text-gray-600 font-inter">
                                Cancer360 is our innovative solution to solving this problem. We are a blockchain based Artificial Intelligence powered cancer diagnostic system
                                that uses advanced smart Artificial Intelligent algorithms that are trained along with advanced thermal imaging to detect 5 types of any stage cancer in an individual's body.
                            </h1>
                        </div>
                    </div>

                    <div className="pb-12 bg-white">
                        <div className="relative">
                            <div className="absolute inset-0 h-2/3 bg-gray-50"></div>
                            <div className="relative mx-auto">
                                <div className="lg:max-w-6xl lg:mx-auto">
                                    <img className="transform scale-110" src={landingDashboardScreen} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </div >
    )
}

export default heroSection
