import React from 'react'

function targetMarket() {
  return (
    <div>
      <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-center">
            <p className="text-base font-semibold text-pink-600">
              Cancers360 Target Market
            </p>
            <h2 className="mt-6 text-3xl font-semibold tracking-tight text-gray-900 lg:mt-8 sm:text-4xl lg:text-5xl">
              Our Specific Market and Business Opportunity
            </h2>
          </div>

          <div className="grid max-w-3xl grid-cols-1 gap-5 mx-auto mt-12 sm:mt-16 md:grid-cols-2 sm:gap-6">

            <div className="transition-all duration-200 bg-white rounded-2xl hover:bg-gray-50 border border border-pink-200">
              <div className="px-4 py-5 sm:p-6">
                <div className="flex items-start">
                  <svg className="w-6 h-6 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <p className="ml-3 text-base font-normal text-gray-900">
                    <span className="font-bold">Public Hospitals and Clinics:</span> Abundant public healthcare facilities in Africa seek cost-effective cancer screening solutions. 
                    Our system offers an affordable option, enhancing early detection capabilities.
                  </p>
                </div>
              </div>
            </div>

            <div className="transition-all duration-200 bg-white rounded-2xl hover:bg-gray-50 border border border-pink-200">
              <div className="px-4 py-5 sm:p-6">
                <div className="flex items-start">
                  <svg className="w-6 h-6 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <p className="ml-3 text-base font-normal text-gray-900">
                    <span className="font-bold">Local Individuals:</span> Many African citizens lack access to convenient and affordable cancer screening. 
                    Our system empowers them with easier, more accessible 
                    screening options, ultimately contributing to early cancer detection and treatment.
                  </p>
                </div>
              </div>
            </div>

            <div className="transition-all duration-200 bg-white rounded-2xl hover:bg-gray-50 border border border-pink-200">
              <div className="px-4 py-5 sm:p-6">
                <div className="flex items-start">
                  <svg className="w-6 h-6 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <p className="ml-3 text-base font-normal text-gray-900">
                    <span className="font-bold">Private Hospitals and Clinics:</span> Booming private healthcare sector in Africa values improved cancer 
                    screening. Our system can be a revenue-generating asset, elevating the quality of their services.
                  </p>
                </div>
              </div>
            </div>

            <div className="transition-all duration-200 bg-white border border border-pink-200 rounded-2xl hover:bg-gray-50">
              <div className="px-4 py-5 sm:p-6">
                <div className="flex items-start">
                  <svg className="w-6 h-6 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>

                  <p className="ml-3 text-base font-normal text-gray-900">
                    <span className="font-bold">Governments and NGOS:</span> African governments prioritize cancer care, and our system aligns with their goals 
                    by boosting screening and early detection rates. NGOs can be valuable partners, extending our reach and awareness.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default targetMarket